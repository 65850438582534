// @flow

/*
 * Aspect Faces forms duck with action creators and reducers
 * see https://github.com/erikras/ducks-modular-redux for pattern guide
 *
 **/
import { fromJS, Map } from "immutable";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import action from "common/utils/flux";

import * as snackbar from "containers/Snackbar/duck";
import * as api from "./api";
import { takeLatest } from "redux-saga/effects";
import { isPlainObject } from "common/utils";
import type { Action } from "common/utils/flux";

export type ActionMeta = {
  structureUrl: ?string,
  getUrl: ?string,
  postUrl: ?string,
  deleteUrl: ?string,
};

export type AfAction = Action & {
  payload: {
    structure: any,
    data: any,
  },
  meta: ActionMeta,
};

// Action types
export const Actions = {
  FORM_CLEAR_DATA: "icpc/aspectFaces/FORM_CLEAR_DATA",
  FORM_EDIT: "icpc/aspectFaces/FORM_EDIT",
  FORM_GET: "icpc/aspectFaces/FORM_GET",
  FORM_GET_SUCCESS: "icpc/aspectFaces/FORM_GET_SUCCESS",
  FORM_GET_ERROR: "icpc/aspectFaces/FORM_GET_ERROR",
  FORM_POST: "icpc/aspectFaces/FORM_POST",
  FORM_POST_SUCCESS: "icpc/aspectFaces/FORM_POST_SUCCESS",
  FORM_POST_ERROR: "icpc/aspectFaces/FORM_POST_ERROR",
  FORM_DELETE: "icpc/aspectFaces/FORM_DELETE",
  FORM_DELETE_SUCCESS: "icpc/aspectFaces/FORM_DELETE_SUCCESS",
  FORM_DELETE_ERROR: "icpc/aspectFaces/FORM_DELETE_ERROR", // for AspectFacesCustom
  FORM_ONLY_STRUCTURE_GET: "icpc/aspectFaces/FORM_ONLY_STRUCTURE_GET",
  FORM_ONLY_STRUCTURE_GET_SUCCESS:
    "icpc/aspectFaces/FORM_ONLY_STRUCTURE_GET_SUCCESS",
  FORM_ONLY_STRUCTURE_GET_ERROR:
    "icpc/aspectFaces/FORM_ONLY_STRUCTURE_GET_ERROR",
};

// Reducers
export default function reducer(
  state: Map<string, any> = fromJS({ data: {}, structure: {} }),
  action: AfAction
) {
  switch (action.type) {
    case Actions.FORM_GET: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state
        .setIn(["data", prop, "isLoading"], true)
        .setIn(["structure", action.meta.structureUrl, "isLoading"], true);
    }
    case Actions.FORM_GET_SUCCESS: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state
        .setIn(
          ["data", prop],
          Map({ data: fromJS(action.payload.data), isLoading: false })
        )
        .setIn(
          ["structure", action.meta.structureUrl],
          Map({ data: fromJS(action.payload.structure), isLoading: false })
        );
    }
    case Actions.FORM_GET_ERROR: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state.merge({
        data: {
          [prop]: {
            isLoading: false,
            isError: true,
            err: action.payload,
          },
        },
        structure: {
          [action.meta.structureUrl]: {
            isLoading: false,
            isError: true,
            err: action.payload,
          },
        },
      });
    }
    case Actions.FORM_ONLY_STRUCTURE_GET: {
      return state.setIn(
        ["structure", action.meta.structureUrl],
        Map({
          isLoading: true,
          isError: false,
          data: null,
        })
      );
    }
    case Actions.FORM_ONLY_STRUCTURE_GET_SUCCESS: {
      return state.setIn(
        ["structure", action.meta.structureUrl],
        Map({
          isLoading: false,
          isError: false,
          data: fromJS(action.payload),
        })
      );
    }
    case Actions.FORM_ONLY_STRUCTURE_GET_ERROR: {
      return state.setIn(
        ["structure", action.meta.structureUrl],
        Map({
          isLoading: false,
          isError: true,
          err: fromJS(action.payload),
        })
      );
    }
    case Actions.FORM_DELETE: {
      return state.setIn(["data", action.meta.getUrl, "isDeleting"], true);
    }
    case Actions.FORM_DELETE_SUCCESS: {
      return state.setIn(
        ["data", action.meta.getUrl],
        Map({
          data: Map(),
          isDeleting: false,
        })
      );
    }
    case Actions.FORM_DELETE_ERROR: {
      return state.setIn(
        ["data", action.meta.getUrl],
        fromJS({
          isDeleting: false,
          isError: true,
        })
      );
    }
    case Actions.FORM_POST: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state.setIn(["data", prop, "isSending"], true);
    }
    case Actions.FORM_POST_SUCCESS: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state.setIn(
        ["data", prop],
        Map({
          data: isPlainObject(action.payload) ? fromJS(action.payload) : Map(),
          isSending: false,
        })
      );
    }
    case Actions.FORM_POST_ERROR: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state
        .setIn(["data", prop, "err"], action.payload)
        .setIn(["data", prop, "isSending"], false)
        .setIn(["data", prop, "isError"], true);
    }
    case Actions.FORM_CLEAR_DATA: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state.setIn(["data", prop], Map({ data: Map() }));
    }

    case Actions.FORM_EDIT: {
      const prop = action.meta.getUrl || action.meta.postUrl;
      return state.setIn(
        ["data", prop, "data", ...action.payload.propNames],
        action.payload.value
      );
    }
    default:
      return state;
  }
}

// Action creators
export function clearForm(meta: Object): AfAction {
  return action(Actions.FORM_CLEAR_DATA, null, meta);
}

export function editForm(
  getUrl: ?string,
  postUrl: ?string,
  propNames: Array<string>,
  value: ?any
): AfAction {
  return action(
    Actions.FORM_EDIT,
    { propNames, value },
    {
      getUrl,
      postUrl,
      structureUrl: null,
    }
  );
}

export function getForm(
  getUrl: ?string = null,
  postUrl: ?string = null,
  structureUrl: ?string = null
): AfAction {
  return action(Actions.FORM_GET, null, {
    getUrl,
    structureUrl,
    postUrl,
  });
}

export function getFormSuccess(
  structurePayload: any,
  dataPayload: any,
  meta: ActionMeta
): AfAction {
  return action(
    Actions.FORM_GET_SUCCESS,
    {
      data: dataPayload,
      structure: structurePayload,
    },
    meta
  );
}

export function getFormError(err: Error, meta: ActionMeta): AfAction {
  return action(Actions.FORM_GET_ERROR, err, meta);
}

export function postForm(
  getUrl: string,
  postUrl: string,
  payload: Map<string, any>
): AfAction {
  return action(Actions.FORM_POST, payload.toJS(), {
    getUrl,
    postUrl,
    structureUrl: null,
  });
}

export function postFormSuccess(payload: any, meta: ActionMeta): AfAction {
  return action(Actions.FORM_POST_SUCCESS, payload, meta);
}

export function postFormError(err: Error, meta: ActionMeta): AfAction {
  return action(Actions.FORM_POST_ERROR, err, meta);
}

export function deleteForm(getUrl: string, deleteUrl: string): AfAction {
  return action(Actions.FORM_DELETE, null, {
    getUrl,
    deleteUrl,
  });
}

export function deleteFormSuccess(meta: ActionMeta): AfAction {
  return action(Actions.FORM_DELETE_SUCCESS, null, meta);
}

export function deleteFormError(err: Error, meta: ActionMeta): AfAction {
  return action(Actions.FORM_DELETE_ERROR, err, meta);
}
export function getFormStructure(structureUrl: string): AfAction {
  return action(Actions.FORM_ONLY_STRUCTURE_GET, null, { structureUrl });
}
export function getFormStructureSuccess(
  payload: any,
  meta: ActionMeta
): AfAction {
  return action(Actions.FORM_ONLY_STRUCTURE_GET_SUCCESS, payload, meta);
}
export function getFormStructureError(err: Error, meta: ActionMeta): AfAction {
  return action(Actions.FORM_ONLY_STRUCTURE_GET_ERROR, err, meta);
}

// Side effects
export function* fetchForm(action: AfAction): Generator<Function, void, void> {
  try {
    const structurePayload = yield call(
      api.fetchStructure,
      action.meta.structureUrl
    );
    const dataPayload = action.meta.getUrl
      ? yield call(api.fetchData, action.meta.getUrl)
      : {};
    if (!structurePayload || !dataPayload) {
      yield put(getFormError(new Error("Received no data"), action.meta));
    } else {
      // backend sends empty string when there is no data for id (null)
      // let's have an empty object for now
      const data =
        dataPayload.data && dataPayload.data instanceof Object
          ? dataPayload.data
          : {};
      yield put(getFormSuccess(structurePayload.data, data, action.meta));
    }
  } catch (err) {
    yield put(getFormError(err, action.meta));
    yield put(snackbar.showErrorMessage(err));
  }
}

export function* sendForm(action: AfAction): Generator<Function, void, void> {
  try {
    const dataPayload = yield call(
      api.postData,
      action.meta.postUrl,
      action.payload
    );
    if (!dataPayload) {
      yield put(postFormError(new Error("Received no data"), action.meta));
    } else {
      yield put(postFormSuccess(dataPayload.data, action.meta));
      yield put(
        snackbar.showMessage("Successfully updated.", { variant: "success" })
      );
    }
  } catch (err) {
    yield put(postFormError(err, action.meta));
    const error =
      err.response && err.response.data ? new Error(err.response.data) : err;
    yield put(snackbar.showErrorMessage(error));
  }
}

export function* deleteFormData(
  action: AfAction
): Generator<Function, void, void> {
  try {
    yield call(api.deleteData, action.meta.deleteUrl);
    yield put(deleteFormSuccess(action.meta));
    yield put(
      snackbar.showMessage("Successfully deleted.", { variant: "success" })
    );
  } catch (err) {
    yield put(deleteFormError(err, action.meta));
    yield put(snackbar.showErrorMessage(err));
  }
}

export function* fetchFormStructure(
  action: AfAction
): Generator<Function, void, void> {
  try {
    const structurePayload = yield call(
      api.fetchStructure,
      action.meta.structureUrl
    );
    if (!structurePayload) {
      yield put(
        getFormStructureError(
          new Error(
            "Something went wrong, please refresh the page or try again later."
          ),
          action.meta
        )
      );
    } else {
      yield put(getFormStructureSuccess(structurePayload.data, action.meta));
    }
  } catch (err) {
    yield put(getFormStructureError(err, action.meta));
    yield put(
      snackbar.showMessage(
        "Something went wrong, please refresh the page or try again later.",
        { variant: "error" }
      )
    );
  }
}

export function* clearData(action: AfAction): Generator<Function, void, void> {
  // we want to clear creator forms
  if (!action.meta.getUrl) {
    yield put(clearForm(action.meta));
  }
}

export function* watchForms(): Generator<
  Array<Generator<Function, void, void>>,
  void,
  void
> {
  yield all([
    takeEvery(Actions.FORM_GET, fetchForm),
    takeEvery(Actions.FORM_POST, sendForm),
    takeEvery(Actions.FORM_DELETE, deleteFormData),
    takeEvery(Actions.FORM_ONLY_STRUCTURE_GET, fetchFormStructure),
    takeLatest(Actions.FORM_POST_SUCCESS, clearData),
  ]);
}

export function* aspectFacesSagas(): Generator<Function, void, void> {
  yield fork(watchForms);
}
