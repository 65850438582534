/**
 * ICPC logo animated loader
 * Made by king robert
 *
 * @flow
 */

import React, { Component } from "react";
import { withStyles } from "@material-ui/core/styles";
import logo_left from "./img/logo_left.png";
import logo_mid from "./img/logo_mid.png";
import logo_right from "./img/logo_right.png";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const styles = {
  root: { width: "100%", height: "100%" },
  blockLeft: {
    animation: "Left-move infinite 2s ease-in-out",
  },
  blockCenter: {
    animation: "Center-move infinite 2s ease-in-out",
  },
  blockRight: {
    animation: "Right-move infinite 2s ease-in-out",
  },
  "@keyframes Center-move": {
    "0%": {
      opacity: 1,
    },
    "25%": {
      opacity: 0.8,
    },
    "50%": {
      opacity: 1,
    },
  },
  "@keyframes Left-move": {
    "0%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
    "40%": {
      transform: "translateX(-20%)",
      opacity: 0.8,
    },
    "50%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
  },
  "@keyframes Right-move": {
    "0%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
    "40%": {
      transform: "translateX(20%)",
      opacity: 0.8,
    },
    "50%": {
      transform: "translateX(0%)",
      opacity: 1,
    },
  },
};

type ICPCLoaderProps = {
  classes: Object,
};

type LogoBlockProps = {
  img: string,
  classNames: string,
};

type PleaseWaitProps = {
  maxDots: number,
};

type PleaseWaitState = {
  dots: string,
  maxDots: string,
};

class PleaseWait extends Component<PleaseWaitProps, PleaseWaitState> {
  state: PleaseWaitState;
  handleDots: () => void;
  intervalNum: ?number = null;
  constructor(props: PleaseWaitProps) {
    super(props);
    this.handleDots = this._handleDots.bind(this);
    let dotsString = "";
    for (let i = 0; i < this.props.maxDots; i++) dotsString += ".";
    this.state = {
      dots: dotsString,
      maxDots: dotsString,
    };
  }
  _handleDots() {
    this.setState((prevState, props) => {
      let newDots = prevState.dots + ".";
      if (newDots.length > props.maxDots) newDots = ".";
      return { dots: newDots };
    });
  }
  componentDidMount() {
    this.intervalNum = window.setInterval(this.handleDots, 600);
  }
  componentWillUnmount() {
    window.clearInterval(this.intervalNum);
  }
  render() {
    const { dots, maxDots } = this.state;
    return (
      <Typography variant="h4" color="textSecondary">
        Please wait<span>{dots}</span>
        <span style={{ visibility: "hidden" }}>
          {maxDots.slice(0, maxDots.length - dots.length)}
        </span>
      </Typography>
    );
  }
}

const LogoBlock = ({ img, classNames }: LogoBlockProps) => {
  return <img src={img} alt="" className={classNames} />;
};

const ICPCLoader = ({ classes }: ICPCLoaderProps) => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className={classes.root}
    >
      <Grid item>
        <div>
          <LogoBlock
            img={logo_left}
            classNames={`${classes.block} ${classes.blockLeft}`}
          />
          <LogoBlock
            img={logo_mid}
            classNames={`${classes.block} ${classes.blockCenter}`}
          />
          <LogoBlock
            img={logo_right}
            classNames={`${classes.block} ${classes.blockRight}`}
          />
        </div>
      </Grid>
      <Grid item>
        <PleaseWait maxDots={3} />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(ICPCLoader);
