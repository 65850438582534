// @flow

// by https://github.com/acdlite/flux-standard-action
// ActionType type: type of action. ActionType is just enum of strings
// Payload: an object with data
// Boolean error: whether we have an error in actionCreators
// Meta: an object with metadata
export type Action = {
  type: string,
  payload: any,
  error: boolean,
  meta: any,
};

export type Dispatch = (action: Action | Array<Action>) => any;

/**
 * utility function to build the standard action
 * we can reduce a boilerplate code a lot by using this
 *
 * @param {string} type Type of action to create.
 * @param {any} payload Payload data. If payload is Error type, sets error to true.
 * @param {any} meta Metadata.
 */
export default function action(
  type: string,
  payload: any = null,
  meta: any = null
): Action {
  if (type === undefined || typeof type !== "string")
    throw new Error("Expects at least one argument of type string");
  return {
    type,
    payload,
    error: payload instanceof Error,
    meta,
  };
}
