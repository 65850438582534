// @flow
// Needed for redux-saga es6 generator support
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "core-js/stable";
import "regenerator-runtime/runtime";
// import all 3rd party stuff
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import configureStore from "./store";
import rootSaga from "./sagas";
import axios from "axios";
import { unregister } from "./registerServiceWorker";
import Security from "./containers/Security";
import AppRoute from "./routes";
import "./index.css";
import "@fontsource/roboto";
import { fromJS, Map } from "immutable";
import Snackbar from "containers/Snackbar";

axios.defaults.baseURL =
  process.env.REACT_APP_SERVER || "https://localhost:9696";
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";

// we probably want to have this in a separate file as it grows
const initialState = {};
// browse history
const history = createBrowserHistory();

const store = configureStore(initialState, history);
store.runSaga(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <Security>
      <AppRoute history={history} />
    </Security>
    <Snackbar />
  </Provider>,
  document.getElementById("root")
);
// Too many pitfalls + doesn't work with keycloak (old CM5 auth provider)
// https://github.com/facebook/create-react-app/issues/2554
// TODO revisit
//registerServiceWorker();
unregister();
