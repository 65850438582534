// @flow
/**
 * Security selectors
 **/
import { createSelector } from "reselect";
import { Map } from "immutable";

const getSecurity = (state: Map<string, any>) => {
  return state.get("security");
};

const isLoading = createSelector(getSecurity, (security: Map<string, any>) => {
  return security.get("loading");
});

const isImpersonating = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("impersonating");
  }
);

const isError = createSelector(getSecurity, (security: Map<string, any>) => {
  return security.get("error");
});

const getCurrentUser = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("currentUser");
  }
);

const getUserRoles = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("userRoles");
  }
);

const isInitialized = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("initialized");
  }
);

const isLoggedIn = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("loggedIn");
  }
);

const getEmail = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("email");
  }
);

const getFullName = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("fullName");
  }
);

const getFirstName = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("firstName");
  }
);

const getLastName = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("lastName");
  }
);

const getUid = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("uid");
  }
);

const getLoginUrl = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("loginUrl");
  }
);

const getLogoutUrl = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("logoutUrl");
  }
);

const getRegisterUrl = createSelector(
  getCurrentUser,
  (currentUser: Map<string, any>) => {
    return currentUser.get("registerUrl");
  }
);

const isRegistered = createSelector(
  getUserRoles,
  (currentUser: Map<string, any>) => {
    return currentUser.get("registered");
  }
);

const getAwsUser = createSelector(getSecurity, (security: Map<string, any>) => {
  return security.get("awsUser");
});

const getUsername = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("username");
  }
);

const getLoginChallenge = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("loginChallenge");
  }
);

const getLoginAttemptFailed = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("loginAttemptFailed");
  }
);

const getLoginChallengeInfo = createSelector(
  getSecurity,
  (security: Map<string, any>) => {
    return security.get("loginChallengeInfo");
  }
);

export {
  getUserRoles,
  getCurrentUser,
  isInitialized,
  isLoading,
  isImpersonating,
  isError,
  isLoggedIn,
  getLastName,
  getFirstName,
  getFullName,
  getEmail,
  getUid,
  getLoginUrl,
  getLogoutUrl,
  getRegisterUrl,
  isRegistered,
  getAwsUser,
  getUsername,
  getLoginChallenge,
  getLoginAttemptFailed,
  getLoginChallengeInfo,
};
