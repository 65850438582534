// @flow
import api from "api";
import * as resources from "resources";
import type { StaffDto } from "./types";

export const fetchSiteDto = (siteId: number) => {
  return api.get(resources.DataGET.SITE_DTO(siteId));
};

export const fetchBreadcrumbs = (siteId: number) => {
  return api.get(resources.DataGET.SITE_BREADCRUMBS(siteId));
};

export const fetchSitesForContest = (contestId: number) => {
  return api.get(resources.DataGET.CONTEST_SITES(contestId));
};

export const addTeam = (team: Object) => {
  return api.post(resources.DataPOST.TEAM_REGISTER_CUSTOM_COACH, team);
};

export const addStaff = (siteId: number, dto: StaffDto) => {
  return api.post(resources.DataPOST.STAFF_MEMBER_CREATE(siteId), dto);
};

export const editStaff = (siteId: number, dto: StaffDto) => {
  return api.put(resources.DataPUT.STAFF_MEMBER_UPDATE(siteId), dto);
};

export const deleteStaff = (staffMemberId: number) => {
  return api.delete(resources.DataDELETE.STAFF_MEMBER_DELETE(staffMemberId));
};

export const getManagers = (siteId: number) => {
  return api.get(resources.DataGET.SITE_MANAGERS(siteId));
};

export const addManager = (siteId: number, personId: number) => {
  return api.post(resources.DataPOST.SITE_MANAGER_ADD(siteId, personId));
};

export const deleteManager = (siteId: number, personId: number) => {
  return api.delete(resources.DataDELETE.SITE_MANAGER_DELETE(siteId, personId));
};

export const fetchTreeNodeByContestByRole = (contestId: number) => {
  return () => api.get(resources.TreeNodeGET.BY_ROLE(contestId));
};

export const getParticipantGroups = (contestId: number) => {
  return api.get(resources.DataGET.CONTEST_PARTICIPANT_GROUPS(contestId));
};

export const downloadEmails = (siteId: number, config: Object) => {
  return api.post(resources.Export.SITE_EMAILS(siteId), config);
};

export const deleteSite = (siteId: number) => {
  return api.delete(resources.DataDELETE.SITE(siteId));
};

export const moveSite = (siteId: number, contestId: number) => {
  return api.put(resources.DataPUT.SITE(siteId, contestId));
};

export const exportPC2 = (siteId: number) => {
  return api.get(resources.Export.SITE_PC2(siteId));
};

export const exportPrintData = (siteId: number) => {
  return api.get(resources.Export.SITE_PRINT_DATA(siteId));
};
