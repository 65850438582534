// @flow
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router/immutable";

// Code-splitted imports
import Loadable from "react-loadable";
import ICPCLoader from "components/ICPCLoader";
import ICPCHotelLoader from "hotel/src/components/ICPCHotelLoader";
import api from "../api";
import { useDispatch } from "react-redux";
import { showErrorMessageSimple } from "../containers/Snackbar/duck";

// import PublicAppHotel  from "hotel/src/index"
// import PrivateAppHotel from "hotel/src/index"

const PublicApp = Loadable({
  loader: () => import("containers/PublicApp"),
  loading: ICPCLoader,
});
const PrivateApp = Loadable({
  loader: () => import("containers/PrivateApp"),
  loading: ICPCLoader,
});
const Login = Loadable({
  loader: () => import("routes/auth/Login"),
  loading: ICPCLoader,
});
const Register = Loadable({
  loader: () => import("routes/auth/Register"),
  loading: ICPCLoader,
});

const HotelPublicApp = Loadable({
  loader: () => import("hotel/src/containers/PublicApp"),
  loading: ICPCLoader,
});
const HotelPrivateApp = Loadable({
  loader: () => import("hotel/src/containers/PrivateApp"),
  loading: ICPCLoader,
});
const TempRedirect = Loadable({
  loader: () => import("routes/public/TempRedirect"),
  loading: ICPCLoader,
});

function AppRoute(props: any) {
  const { history, authInitialized } = props;
  let dispatch = useDispatch();
  api.setError((message) => {
    dispatch(showErrorMessageSimple(message));
  });
  return (
    <ConnectedRouter history={history}>
      <Switch>
        {authInitialized && <Route path="/private" component={PrivateApp} />}
        <Route path="/login" render={(route) => <Login {...route} />} />
        <Route
          path="/register"
          render={(route) => <Register {...route} activate={false} />}
        />
        <Route
          path="/activate"
          render={(route) => <Register {...route} activate={true} />}
        />
        {authInitialized && (
          <Route path="/hotel/private/" component={HotelPrivateApp} />
        )}
        <Route path="/hotel/public/" component={HotelPublicApp} />
        <Route path="/hotel/" component={HotelPublicApp} />
        <Route
          path="/worldfinals/acmicpc/ICPC-Policies-and-Procedures-04-16-2018-c.pdf"
          component={TempRedirect}
        />
        <Route path="/" component={PublicApp} />
      </Switch>
    </ConnectedRouter>
  );
}

export default AppRoute;
