// @flow

export const LoginChallenge = {
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  CONFIRM_REGISTRATION: "CONFIRM_REGISTRATION",
  FINISH_REGISTRATION: "FINISH_REGISTRATION",
  CONFIRM_CHANGE_USERNAME: "CONFIRM_CHANGE_USERNAME",
};
