// @flow

import api from "api";
import api1 from "../../hotel/src/api";
import * as resources from "resources";

const ASPECT_FACES = "/icpchotel";

export const fetchData = (url: string) => {
  const finalAPI = getAPI(url);
  return finalAPI.get(url);
};

function getAPI(url) {
  return url.includes(ASPECT_FACES) ? api1 : api;
}
export const fetchStructure = (url: string) => {
  const finalAPI = getAPI(url);
  return finalAPI.get(url);
};
export const postData = (url: string, data: any) => {
  const finalAPI = getAPI(url);
  return finalAPI.post(url, data);
};
export const deleteData = (url: string) => {
  const finalAPI = getAPI(url);
  return finalAPI.delete(url);
};
export const fetchProfileSimpleDto = (currentContestId: number) => {
  return api1.get(resources.DataGET.ALL_PROFILE_SIMPLE_DTOS(currentContestId));
};

// components
export const fetchIua = (id: number) => {
  return api.get(resources.DataGET.INSTITUTION_IUA_BY_ID(id));
};

export const fetchIuaSuggestDto = (id: number) => {
  return api.get(resources.DataGET.INSTITUTION_SUGGEST_BY_IUA_ID(id));
};

export const fetchPerson = (id: number) => {
  return api.get(resources.DataGET.PERSON_MAIN_INFO(id));
};

export const fetchPersonSuggestDto = (id: number) => {
  return api.get(resources.DataGET.PERSON_NAME_BY_ID(id));
};

export const isEmailUnique = (email: string) => {
  return api.get(resources.DataGET.USERNAME_AVAILABLE(email));
};
