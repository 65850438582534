/*
 * @flow
 * Context menu duck with operations such as insertItem, removeItem, replaceItem
 *
 **/
import action from "common/utils/flux";
import { Map } from "immutable";
import type { Action } from "common/utils/flux";

// Action types
export const Actions = {
  NAVBAR_RESET: "public/navbar/NAVBAR_RESET",
  SCROLL_PERCENTAGE_SET: "public/navbar/SCROLL_PERCENTAGE_SET",
  SHOW_SCROLL_SET: "public/navbar/SHOW_SCROLL_SET",
  DRAWER_PERSISTENT_SET: "public/navbar/DRAWER_PERSISTENT_SET",
};

const defaultStore = Map({
  scrollPercentage: 0,
  showScroll: true,
  persistent: false,
});

// Reducer
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.NAVBAR_RESET:
      return defaultStore;
    case Actions.SCROLL_PERCENTAGE_SET:
      return state.set("scrollPercentage", action.payload);
    case Actions.SHOW_SCROLL_SET:
      return state.set("showScroll", action.payload);
    case Actions.DRAWER_PERSISTENT_SET:
      return state.set("persistent", action.payload);
    default:
      return state;
  }
}

// Action creators
export function updateScrollPercentage(newPercentage: number) {
  return action(Actions.SCROLL_PERCENTAGE_SET, newPercentage);
}
export function updateShowScroll(newState: boolean) {
  return action(Actions.SHOW_SCROLL_SET, newState);
}
export function updatePersistent(persistent: boolean) {
  return action(Actions.DRAWER_PERSISTENT_SET, persistent);
}
export function resetNavbar() {
  return action(Actions.NAVBAR_RESET);
}
