// @flow
/**
 * PrivateSidebar selectors
 **/
import { List, Map } from "immutable";
import { createSelector } from "reselect";
import { findKeys } from "./utils";

const getPrivateApp = (state: Map<string, any>) => {
  return state.get("privateApp");
};

const getDrawerVisibilityState = createSelector(
  getPrivateApp,
  (sidebar: Map<string, any>) => {
    return sidebar.get("drawerVisibilityState");
  }
);

const isDrawerOpen = createSelector(
  getDrawerVisibilityState,
  (drawerVisibilityState: Map<string, boolean>) => {
    return drawerVisibilityState.get("isDrawerOpen");
  }
);

const isMobileDrawerOpen = createSelector(
  getDrawerVisibilityState,
  (drawerVisibilityState: Map<string, boolean>) => {
    return drawerVisibilityState.get("isMobileDrawerOpen");
  }
);

const getMenuItems = createSelector(
  getPrivateApp,
  (sidebar: Map<string, any>) => {
    return sidebar.get("menuItems");
  }
);

const getForAdmin = createSelector(
  getMenuItems,
  (items: List<Map<string, any>>) => {
    return items;
  }
);

const getForCanSeeContest = createSelector(
  getForAdmin,
  (items: List<Map<string, any>>) => {
    const itemsToRemove = ["search", "other"];
    let newItems = items;
    for (let itemToRemove of itemsToRemove) {
      const keys = findKeys(newItems, itemToRemove);
      if (!keys) continue;
      newItems = newItems.removeIn(keys);
    }
    return newItems;
  }
);

const getForRegularUser = createSelector(
  getForCanSeeContest,
  (items: List<Map<string, any>>) => {
    const itemsToRemove = ["contests", "search"];
    let newItems = items;
    for (let itemToRemove of itemsToRemove) {
      const keys = findKeys(newItems, itemToRemove);
      if (!keys) continue;
      newItems = newItems.removeIn(keys);
    }
    return newItems;
  }
);

export {
  isDrawerOpen,
  isMobileDrawerOpen,
  getForAdmin,
  getForCanSeeContest,
  getForRegularUser,
};
