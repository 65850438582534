// @flow

import { fromJS, Map, Set } from "immutable";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { push } from "connected-react-router/immutable";

import * as api from "./api";
import action from "common/utils/flux";
import type { Action } from "common/utils/flux";
import { showMessage, showErrorMessage } from "containers/Snackbar/duck";

const defaultStore = Map({
  loading: Set(),
  error: Set(),
  settingsStructure: null,
  settings: Map({
    firstLine: "",
    secondLine: "",
    representationLine: "",
    canPublishHonorableCert: false,
    manualHonorableCitation: false,
    honorableMentionCitation: "",
    canPublishChallengeCert: false,
    challengeCitation: "",
    paperSize: "",
    fontSize: "",
    useCustomBackground: false,
    certificateTitle: "",
    contestDetails: "",
    footerPositionX: "0",
    footerPositionY: "0",
    footerScale: "1",
    isFooterUpdated: false,
    footerImage: null,
  }),
});

// Action types
export const Actions = {
  //async actions for fetching certificate settings structure
  LOAD_SETTINGS_STRUCTURE: "icpc/certificate/LOAD_SETTINGS_STRUCTURE",
  LOAD_SETTINGS_STRUCTURE_SUCCESS:
    "icpc/certificate/LOAD_SETTINGS_STRUCTURE_SUCCESS",
  LOAD_SETTINGS_STRUCTURE_ERROR:
    "icpc/certificate/LOAD_SETTINGS_STRUCTURE_ERROR",

  EDIT_SETTINGS: "icpc/certificate/EDIT_SETTINGS",

  //async actions for fetching certificate settings
  LOAD_SETTINGS: "icpc/certificate/LOAD_SETTINGS",
  LOAD_SETTINGS_SUCCESS: "icpc/certificate/LOAD_SETTINGS_SUCCESS",
  LOAD_SETTINGS_ERROR: "icpc/certificate/LOAD_SETTINGS_ERROR",

  //async actions for updating settings
  UPDATE_SETTINGS: "icpc/certificate/UPDATE_SETTINGS",
  UPDATE_SETTINGS_SUCCESS: "icpc/certificate/UPDATE_SETTINGS_SUCCESS",
  UPDATE_SETTINGS_ERROR: "icpc/certificate/UPDATE_SETTINGS_ERROR",
};

//Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.LOAD_SETTINGS_STRUCTURE:
      return state.merge({
        loading: state.get("loading").add("settingsStructureLoad"),
        error: state.get("error").remove("settingsStructureLoad"),
      });
    case Actions.LOAD_SETTINGS_STRUCTURE_SUCCESS:
      return state.merge({
        settingsStructure: fromJS(action.payload),
        settings: state.get("settings").merge({
          representationLine: action.payload.fields[2].options[0][0],
          paperSize: action.payload.fields[5].options[0][0],
          fontSize: action.payload.fields[6].options[4][0],
        }),
        loading: state.get("loading").remove("settingsStructureLoad"),
      });
    case Actions.LOAD_SETTINGS_STRUCTURE_ERROR:
      return state.merge({
        loading: state.get("loading").remove("settingsStructureLoad"),
        error: state.get("error").add("settingsStructureLoad"),
      });
    case Actions.EDIT_SETTINGS:
      return state.setIn(
        ["settings", ...action.payload.propNames],
        action.payload.value
      );
    case Actions.LOAD_SETTINGS:
      return state.merge({
        settings: state.get("loading").add("settingsLoad"),
        error: state.get("error").remove("settingsLoad"),
      });
    case Actions.LOAD_SETTINGS_SUCCESS:
      return state.merge({
        settings: fromJS(action.payload),
        loading: state.get("loading").remove("settingsLoad"),
      });
    case Actions.LOAD_SETTINGS_ERROR:
      return state.merge({
        loading: state.get("loading").remove("settingsLoad"),
        error: state.get("error").add("settingsLoad"),
      });
    case Actions.UPDATE_SETTINGS:
      return state.merge({
        loading: state.get("loading").add("settingsUpdate"),
        error: state.get("error").remove("settingsUpdate"),
      });
    case Actions.UPDATE_SETTINGS_SUCCESS:
      return state.merge({
        xwiki: fromJS(action.payload),
        loading: state.get("loading").remove("settingsUpdate"),
      });
    case Actions.UPDATE_SETTINGS_ERROR:
      return state.merge({
        loading: state.get("loading").remove("settingsUpdate"),
        error: state.get("error").add("settingsUpdate"),
      });
    default:
      return state;
  }
}

//Action creators
export function fetchCertificateSettings(contestId: number): Action {
  return fetchSettingsStructure(contestId);
}
function fetchSettingsStructure(contestId: number): Action {
  return action(Actions.LOAD_SETTINGS_STRUCTURE, null, { contestId });
}

function fetchSettingsStructureSuccess(payload: Object): Action {
  return action(Actions.LOAD_SETTINGS_STRUCTURE_SUCCESS, payload);
}

function fetchSettingsStructureError(err: Error): Action {
  return action(Actions.LOAD_SETTINGS_STRUCTURE_ERROR, err);
}

export function editSettings(propNames: Array<string>, value: ?any): Action {
  return action(Actions.EDIT_SETTINGS, { propNames, value });
}

function fetchSettings(contestId: number): Action {
  return action(Actions.LOAD_SETTINGS, null, { contestId });
}

function fetchSettingsSuccess(payload: Object): Action {
  return action(Actions.LOAD_SETTINGS_SUCCESS, payload);
}

function fetchSettingsError(err: Error): Action {
  return action(Actions.LOAD_SETTINGS_ERROR, err);
}

export function updateSettings(contestId: number, settings: Object): Action {
  return action(Actions.UPDATE_SETTINGS, settings, { contestId });
}

function updateSettingsSuccess(settings: Object): Action {
  return action(Actions.UPDATE_SETTINGS_SUCCESS, settings);
}

function updateSettingsError(err: Error): Action {
  return action(Actions.UPDATE_SETTINGS_ERROR, err);
}

//Side effects
function* fetchSettingsStructureAsync(
  action: Action
): Generator<Function, void, void> {
  try {
    const { contestId } = action.meta;
    const payload = yield call(api.fetchCertSettingsStructure);
    if (!payload) {
      const err = new Error("Failed to connect.");
      yield put(showMessage(err));
      yield put(fetchSettingsStructureError(err));
    } else {
      yield put(fetchSettingsStructureSuccess(payload.data));
      yield put(fetchSettings(contestId));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(fetchSettingsStructureError(err));
  }
}

function* fetchSettingsAsync(action: Action): Generator<Function, void, void> {
  try {
    const { contestId } = action.meta;
    const payload = yield call(api.fetchCertSettings, contestId);
    if (!payload) {
      const err = new Error("Certificate settings has not been created.");
      yield put(showMessage(err));
      yield put(fetchSettingsError(err));
    } else {
      yield put(fetchSettingsSuccess(payload.data));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(push(`/private/dashboard`));
    yield put(fetchSettingsError(err));
  }
}

function* updateSettingsAsync(action: Action): Generator<Function, void, void> {
  try {
    const { contestId } = action.meta;
    const payload = yield call(
      api.updateCertSettings,
      contestId,
      action.payload
    );
    if (!payload) {
      const err = new Error("Received no data.");
      yield put(showMessage(err));
      yield put(updateSettingsError(err));
    } else {
      yield put(updateSettingsSuccess(payload.data));
      yield put(showMessage("Updated the certificate settings successfully."));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(updateSettingsError(err));
  }
}

function* watchFetchSettingsStructure(): Generator<Function, void, void> {
  yield takeLatest(
    Actions.LOAD_SETTINGS_STRUCTURE,
    fetchSettingsStructureAsync
  );
}

function* watchFetchSettings(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOAD_SETTINGS, fetchSettingsAsync);
}

function* watchUpdateSettings(): Generator<Function, void, void> {
  yield takeLatest(Actions.UPDATE_SETTINGS, updateSettingsAsync);
}

export function* certificateSagas(): Generator<Function, void, void> {
  yield all([
    fork(watchFetchSettingsStructure),
    fork(watchFetchSettings),
    fork(watchUpdateSettings),
  ]);
}
