// @flow
/**
 * This file configures axios instance.
 * Import this instance every time you want to do a server call.
 * Usage:
 *    import api from "api";
 *    api.get("/users", ...) ...will produce full URL for a request
 */

import axios from "axios";

const baseApi = axios.create();
baseApi.defaults.baseURL = process.env.REACT_APP_SERVER;

const api = {
  defaults: baseApi.defaults,
  get: (url, config?, showPopup = true, failPromise = null) => {
    setExceptionHandler(showPopup, failPromise);
    return baseApi.get(url, config);
  },
  post: (url, data?, config?, showPopup = true, failPromise = null) => {
    setExceptionHandler(showPopup, failPromise);
    return baseApi.post(url, data, config);
  },
  patch: (url, data?, config?, showPopup = true, failPromise = null) => {
    setExceptionHandler(showPopup, failPromise);
    return baseApi.patch(url, data, config);
  },
  put: (url, data?, config?, showPopup = true, failPromise = null) => {
    setExceptionHandler(showPopup, failPromise);
    return baseApi.put(url, data, config);
  },
  delete: (url, config?, showPopup = true, failPromise = null) => {
    setExceptionHandler(showPopup, failPromise);
    return baseApi.delete(url, config);
  },
  setError: (callback) => {
    baseApi.showMessageCallback = callback;
  },
  interceptors: baseApi.interceptors,
};

function setExceptionHandler(showPopup, failPromise) {
  baseApi.interceptors.response.use(
    (response) => response,
    (error) => {
      if (!error.response) {
        baseApi.showMessageCallback(error.message);
      } else if (showPopup) {
        baseApi.showMessageCallback(
          error.response?.data?.message
            ? error.response.data.message
            : typeof error.response.data === "string"
            ? error.response.data
            : "Something went wrong"
        );
      }
      if (failPromise) {
        failPromise();
      }
      throw error;
    }
  );
}

export const CLIENT = process.env.REACT_APP_CLIENT;
export const SERVER = process.env.REACT_APP_SERVER;
export const HOTEL_SERVER = process.env.REACT_APP_HOTEL;
export const PROFILE_SERVER = process.env.REACT_APP_PROFILE;
export default api;
