// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// action creators and reducers
import { init } from "./duck";
// selectors to select store properties and pass them as props
import * as s from "./selectors";
import Fullscreen from "components/Fullscreen";
import Amplify from "aws-amplify";

Amplify.configure({
  // Amplify requires app client with no secret
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    mandatorySignIn: true,
    authenticationFlowType: "USER_SRP_AUTH",
    oauth: {
      domain: process.env.REACT_APP_COGNITO_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: `${process.env.REACT_APP_CLIENT}/private`,
      redirectSignOut: `${process.env.REACT_APP_CLIENT}/private`,
      responseType: "token",
    },
  },
});

class Security extends Component<Props> {
  componentDidMount() {
    if (!this.props.initialized) {
      this.props.init();
    }
  }
  render() {
    if (this.props.error) return null;

    const children = React.Children.map(this.props.children, (child) => {
      const childProps = {
        authInitialized: this.props.initialized,
        ...this.props,
      };
      if (React.isValidElement(child)) {
        return React.cloneElement(child, childProps);
      }
      return child;
    });
    return <div>{children}</div>;
  }
}

/*
  Here we specify which state needs to be made available to the component
  our state.data and state.count will be available via this.props.data and this.props.count
  Gives state (redux store) and props of ICPCTable to all selectors
*/
const mapStateToProps = (state: Object, props: Object) =>
  createStructuredSelector({
    loading: s.isLoading,
    error: s.isError,
    initialized: s.isInitialized,
  });

/*
  This will bind our actions to dispatch (make the fire-able)
  and make the actions available via props
*/
export function mapDispatchToProps(dispatch: any) {
  return bindActionCreators({ init }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(Security);
