// @flow
import api from "api";
import * as resources from "resources";

export const fetchCertSettingsStructure = () => {
  return api.get(resources.FormStructure.CERTIFICATE_SETTINGS);
};

export const fetchCertSettings = (contestId: number) => {
  return api.get(resources.DataGET.CERT_SETTINGS(contestId));
};

const createFormData = (certSettings: Object, footerAdded: boolean) => {
  const formData = new FormData();
  if (footerAdded) {
    formData.append("certificateFooter", certSettings.get("certificateFooter"));
  }
  const settings = {
    firstLine: certSettings.get("firstLine"),
    secondLine: certSettings.get("secondLine"),
    representationLine: certSettings.get("representationLine"),
    canPublishHonorableCert: certSettings.get("canPublishHonorableCert"),
    manualHonorableCitation: certSettings.get("manualHonorableCitation"),
    honorableMentionCitation: certSettings.get("honorableMentionCitation"),
    canPublishChallengeCert: certSettings.get("canPublishChallengeCert"),
    challengeCitation: certSettings.get("challengeCitation"),
    paperSize: certSettings.get("paperSize"),
    fontSize: certSettings.get("fontSize"),
    useCustomBackground: certSettings.get("useCustomBackground"),
    certificateTitle: certSettings.get("certificateTitle"),
    contestDetails: certSettings.get("contestDetails"),
    footerPositionX: certSettings.get("footerPositionX"),
    footerPositionY: certSettings.get("footerPositionY"),
    footerScale: certSettings.get("footerScale"),
  };

  formData.append("certificateSettings", JSON.stringify(settings));
  return formData;
};

export const updateCertSettings = (contestId: number, certSettings: Object) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const formData = createFormData(
    certSettings,
    certSettings.get("isFooterUpdated")
  );
  return api.put(
    resources.DataPUT.CERT_SETTINGS_UPDATE(contestId),
    formData,
    config
  );
};

export const downloadCustBackgroundPreview = (certSettings: Object) => {
  const config = { headers: { "Content-Type": "multipart/form-data" } };
  const formData = createFormData(
    certSettings,
    certSettings.get("footerImage") != null
  );
  return api.put(
    resources.DataPUT.CUSTOM_BACKGROUND_PREVIEW(),
    formData,
    config
  );
};
