// @flow
import api from "api";
import * as resources from "resources";

export const fetchRoot = () => {
  return api.get(resources.DataGET.HELP_TOPIC_ROOT());
};

export const fetchTopic = (key: string) => {
  return api.get(resources.DataGET.HELP_TOPIC_BY_KEY(key));
};

export const search = (query: string) => {
  return api.get(resources.DataGET.HELP_TOPIC_SEARCH(), {
    params: { q: query },
  });
};

export const postIssue = (topicId: number, dto: Object) => {
  return api.post(resources.DataPOST.HELP_POST_ISSUE(topicId), dto);
};

export const updateTopic = (topicDTO: Object) => {
  return api.put(resources.DataPUT.HELP_TOPIC_UPDATE(), topicDTO);
};

export const addTopic = (topicDTO: Object) => {
  return api.post(resources.DataPOST.HELP_TOPIC_ADD(), topicDTO);
};
