// @flow
/**
 * Person registration selectors
 **/
import { createSelector } from "reselect";
import { Map } from "immutable";

const getPersonRegistration = (state: Map<string, any>) => {
  return state.get("personRegistration");
};

const getProps = createSelector(
  getPersonRegistration,
  (personRegistration: Map<string, any>) => {
    return personRegistration.remove("extraFields").toJS();
  }
);

const getExtraFields = createSelector(
  getPersonRegistration,
  (personRegistration: Map<string, any>) => {
    return personRegistration.get("extraFields");
  }
);

export { getProps, getExtraFields };
