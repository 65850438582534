// @flow

import { fromJS, Map, Set } from "immutable";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";

import { showMessage, showErrorMessage } from "containers/Snackbar/duck";
import * as api from "./api";
import action from "common/utils/flux";
import type { Action } from "common/utils/flux";

const defaultStore = Map({
  loading: Set(),
  error: Set(),
  xWikiPage: null,
});

const HTMLDecoderEncoder = require("html-encoder-decoder");

// Action types
export const Actions = {
  //async actions for fetching xWiki contents
  LOAD_XWIKI_PAGE: "public/xWiki/LOAD_XWIKI_PAGE",
  LOAD_XWIKI_PAGE_SUCCESS: "public/xWiki/LOAD_XWIKI_PAGE_SUCCESS",
  LOAD_XWIKI_PAGE_ERROR: "public/xWiki/LOAD_XWIKI_PAGE_ERROR",
  //async actions for updating xWiki contents
  UPDATE_XWIKI_PAGE: "public/xWiki/UPDATE_XWIKI_PAGE",
  UPDATE_XWIKI_PAGE_SUCCESS: "public/xWiki/UPDATE_XWIKI_PAGE_SUCCESS",
  UPDATE_XWIKI_PAGE_ERROR: "public/xWiki/UPDATE_XWIKI_PAGE_ERROR",
};

//Reducers
export default function reducer(
  state: Map<string, any> = defaultStore,
  action: Action
) {
  switch (action.type) {
    case Actions.LOAD_XWIKI_PAGE:
      return state.merge({
        loading: state.get("loading").add("pageLoad"),
        error: state.get("error").remove("pageLoad"),
      });
    case Actions.LOAD_XWIKI_PAGE_SUCCESS:
      return state.merge({
        xWikiPage: fromJS(action.payload),
        loading: state.get("loading").remove("pageLoad"),
      });
    case Actions.LOAD_XWIKI_PAGE_ERROR:
      return state.merge({
        loading: state.get("loading").remove("pageLoad"),
        error: state.get("error").add("pageLoad"),
      });
    case Actions.UPDATE_XWIKI_PAGE:
      return state.merge({
        loading: state.get("loading").add("pageUpdate"),
        error: state.get("error").remove("pageUpdate"),
      });
    case Actions.UPDATE_XWIKI_PAGE_SUCCESS:
      console.log("SUCCESS UPDATE");
      console.log(action.payload);
      action.payload.content = HTMLDecoderEncoder.decode(
        action.payload.content
      );
      return state.merge({
        xWikiPage: fromJS(action.payload),
        loading: state.get("loading").remove("pageUpdate"),
      });
    case Actions.UPDATE_XWIKI_PAGE_ERROR:
      return state.merge({
        loading: state.get("loading").remove("pageUpdate"),
        error: state.get("error").add("pageUpdate"),
      });
    default:
      return state;
  }
}

//Action creators
export function fetchXWikiPage(
  wiki: string,
  space: string,
  page: string
): Action {
  return action(Actions.LOAD_XWIKI_PAGE, null, { wiki, space, page });
}
function fetchXWikiPageSuccess(payload: Object): Action {
  return action(Actions.LOAD_XWIKI_PAGE_SUCCESS, payload);
}
function fetchXWikiPageError(err: Error): Action {
  return action(Actions.LOAD_XWIKI_PAGE_ERROR, err);
}

export function updateXWikiPage(xWiki: Object): Action {
  return action(Actions.UPDATE_XWIKI_PAGE, xWiki);
}
function updateXWikiPageSuccess(payload: Object): Action {
  return action(Actions.UPDATE_XWIKI_PAGE_SUCCESS, payload);
}
function updateXWikiPageError(err: Error): Action {
  return action(Actions.UPDATE_XWIKI_PAGE_ERROR, err);
}

//Side effects
function* fetchXWikiPageAsync(action: Action): Generator<Function, void, void> {
  try {
    const { wiki, space, page } = action.meta;
    const payload = yield call(api.fetchXWikiPage, wiki, space, page);
    let decoded = null;

    console.log((decoded = HTMLDecoderEncoder.decode(payload.data.content)));

    console.log("BEFORE FETCH: ");
    console.log(payload.data);
    payload.data.content = decoded;
    console.log("AFTER FETCH DECODED: ");
    console.log(payload.data);

    if (!payload) {
      const err = new Error("Page not found");
      yield put(showMessage(err));
      yield put(fetchXWikiPageError(err));
    } else {
      yield put(fetchXWikiPageSuccess(payload.data));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(fetchXWikiPageError(err));
  }
}

function* updateXWikiPageAsync(
  action: Action
): Generator<Function, void, void> {
  try {
    const payload = yield call(api.updateXWikiPage, action.payload);
    console.log("BEFORE UPDATE: ");
    console.log(payload.data);

    // let encoded = null;
    // console.log(encoded = HTMLDecoderEncoder.encode(payload.data.content));

    payload.data.content = HTMLDecoderEncoder.encode(payload.data.content);
    console.log("AFTER UPDATE ENCODED: ");
    console.log(payload.data);

    if (!payload) {
      const err = new Error("Received no data.");
      console.log(err);
      yield put(showMessage(err));
      yield put(updateXWikiPageError(err));
    } else {
      yield put(updateXWikiPageSuccess(payload.data));
      yield put(showMessage("Updated the content successfully."));
    }
  } catch (err) {
    yield put(showErrorMessage(err));
    yield put(updateXWikiPageError(err));
  }
}

function* watchFetchXWikiContent(): Generator<Function, void, void> {
  yield takeLatest(Actions.LOAD_XWIKI_PAGE, fetchXWikiPageAsync);
}

function* watchUpdateXWikiContent(): Generator<Function, void, void> {
  yield takeLatest(Actions.UPDATE_XWIKI_PAGE, updateXWikiPageAsync);
}

export function* xWikiSagas(): Generator<Function, void, void> {
  yield all([fork(watchFetchXWikiContent), fork(watchUpdateXWikiContent)]);
}
