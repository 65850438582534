// @flow
/**
 * AspectFaces selectors
 **/
import { createSelector } from "reselect";
import { Map } from "immutable";

const getGlobalsStore = (state: Map<string, any>) => {
  return state.get("globals");
};

const getWorldFinalsYear = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("worldFinalsYear");
  }
);

const getRegionalsYear = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("regionalsYear");
  }
);

const getWorldFinalsContestId = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("worldFinalsContestId");
  }
);

const getVideoStatus = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("videoStatus");
  }
);

const getVideoName = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("videoName");
  }
);

const getSelectedYear = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("selectedYear");
  }
);

const getYear = createSelector(
  getRegionalsYear,
  getWorldFinalsYear,
  (regYear: number, wfYear: number) => {
    return regYear > wfYear ? regYear : wfYear; //check with others (this says that the year used in year picker will be the max between regional and WF)
    // return wfYear;
  }
);

const isLoading = createSelector(
  getGlobalsStore,
  (globals: Map<string, any>) => {
    return globals.get("loading");
  }
);

const isError = createSelector(getGlobalsStore, (globals: Map<string, any>) => {
  return globals.get("error");
});

export {
  getWorldFinalsYear,
  getRegionalsYear,
  getWorldFinalsContestId,
  getSelectedYear,
  getVideoStatus,
  getVideoName,
  getYear,
  isLoading,
  isError,
};
