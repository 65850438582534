// @flow

import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
// action creators and reducers
import { hideMessage, showMessage } from "./duck";
// selectors to select store properties and pass them as props
import * as s from "./selectors";

import Snackbar from "@material-ui/core/Snackbar";
import CustomSnackbarContent from "./components/CustomSnackbarContent";
import Action from "./components/Action";
import type { Vertical, Horizontal, Variant } from "./types";

type Props = {
  open: boolean,
  message: string,
  vertical: Vertical,
  horizontal: Horizontal,
  autoHideDuration: number,
  showMessage: (string) => void,
  hideMessage: () => void,
  dispatch: Function,
  action?: Object,
  actionText?: string,
  classes: Object,
  variant: Variant,
};

class SnackbarContainer extends Component<Props> {
  handleRequestClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.hideMessage();
  };

  render() {
    const {
      vertical,
      horizontal,
      open,
      message,
      variant,
      autoHideDuration,
      hideMessage,
      action,
      dispatch,
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={autoHideDuration}
        ContentProps={{
          "aria-describedby": "icpc-message",
        }}
      >
        <CustomSnackbarContent
          action={
            <Action
              onClose={this.handleRequestClose}
              onAction={
                action
                  ? () => {
                      hideMessage();
                      typeof action === "function"
                        ? action()
                        : dispatch(action);
                    }
                  : undefined
              }
            />
          }
          message={message}
          onClose={this.handleRequestClose}
          variant={variant || "success"}
        />
      </Snackbar>
    );
  }
}

const mapStateToProps = (state: Object, props: Object) =>
  createStructuredSelector({
    open: s.selectOpen,
    message: s.selectMessage,
    vertical: s.selectVertical,
    horizontal: s.selectHorizontal,
    autoHideDuration: s.selectAutoHideDuration,
    action: s.selectAction,
    actionText: s.selectActionText,
    variant: s.selectVariant,
  });

function mapDispatchToProps(dispatch: any) {
  return {
    ...bindActionCreators({ showMessage, hideMessage }, dispatch),
    dispatch,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SnackbarContainer);
