// @flow
/**
 * This file configures axios instance.
 * Import this instance every time you want to do a server call.
 * Usage:
 *    import api from "hotel/src/api";
 *    api.get("/users", ...) ...will produce full URL for a request
 */

import axios from "axios";
import Auth from "@aws-amplify/auth";

const api = axios.create();
api.interceptors.request.use((config) => {
  return new Promise((resolve, reject) => {
    Auth.currentSession()
      .then((user) => {
        config.headers = {
          ...config.headers,
          ...{ Authorization: "Bearer " + user.idToken.jwtToken },
        };
        resolve(config);
      })
      .catch((err) => {
        reject("Not currently logged in: " + err);
      });
  });
});

export const CLIENT = process.env.REACT_APP_CLIENT;
export const SERVER = process.env.REACT_APP_HOTEL;
export const CONTEST_CLIENT = process.env.REACT_APP_CLIENT;
export const CONTEST = process.env.REACT_APP_CONTEST;
api.defaults.baseURL = SERVER;

export default api;
