// @flow
import api from "api";
import * as resources from "resources";

export const fetchRegCompleteStatus = (personId: number) => {
  return api.get(resources.DataGET.PERSON_REGCOMPLETE_STATUS(personId));
};

export const fetchPersonDto = (personId: number) => {
  return api.get(resources.DataGET.PERSON_NAME_BY_ID(personId));
};

export const fetchRegistrationStatusDto = (personId: number) => {
  return api.get(resources.DataGET.PERSON_REGISTRATION_STATUS(personId));
};

export const fetchReferencesDto = (personId: number, year: number) => {
  return api.get(resources.DataGET.PERSON_REFERENCES(personId, year));
};

export const fetchExtraFields = (personId: number) => {
  return api.get(resources.DataGET.PERSON_EXTRA_FIELDS(personId));
};

export const postExtraFields = (personId: number, fields: Object) => {
  return api.post(resources.DataPOST.PERSON_EXTRA_FIELDS(personId), fields);
};

export const fetchICPCId = (publicKey: string) => {
  return () => api.get(resources.DataGET.ICPCId(publicKey));
};

export const fetchICPCIdByPerson = (personId: number) => {
  return () => api.get(resources.DataGET.ICPCId_BY_PERSON(personId));
};

export const createICPCId = (personId: number) => {
  return api.post(resources.DataPOST.PERSON_ICPCID_CREATE(personId));
};

export const switchEnabled = (personId: number) => {
  return api.put(resources.DataPUT.PERSON_SWITCH_ENABLED(personId));
};

export const switchRegcomplete = (personId: number) => {
  return api.put(resources.DataPUT.PERSON_SWITCH_REGCOMPLETE(personId));
};

export const fetchIcpcIdKey = (personId: number) => {
  return api.get(resources.DataGET.ICPCIdKey(personId));
};
