/*
 * @flow
 * Context menu duck with operations such as insertItem, removeItem, replaceItem
 *
 **/
import { fromJS, List, Map } from "immutable";

import action from "common/utils/flux";
import defaultMenu from "./defaultMenu";
import { findKeys } from "./utils";
import { isDrawerOpen } from "common/utils/localStorage";
import type { Action } from "common/utils/flux";
import type { MenuItem } from "./types";

const defaultStore = {
  drawerVisibilityState: {
    isDrawerOpen: isDrawerOpen(true),
    isMobileDrawerOpen: false,
  },
  menuItems: defaultMenu,
};

// Action types
export const Actions = {
  INSERT_AFTER: "private/sidebar/INSERT_AFTER",
  INSERT_BEFORE: "private/sidebar/INSERT_BEFORE",
  REMOVE: "icpc/security/REMOVE_MENU_ITEM",
  REPLACE: "private/sidebar/REPLACE_MENU_ITEM",
  REPLACE_ALL: "private/sidebar/REPLACE_ALL_MENU_ITEMS",
  RESET: "private/sidebar/RESET",
  APPEND: "private/sidebar/APPEND",
  PREPEND: "private/sidebar/PREPEND",
  // UI state
  DRAWER_TOGGLE: "private/sidebar/DRAWER_TOGGLE",
  DRAWER_OPEN: "private/sidebar/DRAWER_OPEN",
  DRAWER_CLOSE: "private/sidebar/DRAWER_CLOSE",
};

type SidebarAction = Action & {
  payload:
    | {
        name: string,
        value: MenuItem,
      }
    | boolean,
};

// Reducers
function itemsReducer(
  state: List<Map<string, any>> = fromJS(defaultMenu),
  action: SidebarAction
) {
  switch (action.type) {
    case Actions.INSERT_AFTER: {
      const indexes = findKeys(state, action.payload.name);
      if (!indexes) return state;
      const last = indexes.pop();
      const substate = state.getIn(indexes);
      if (!substate || !List.isList(substate)) return state;
      return state.setIn(
        indexes,
        substate.insert(last + 1, fromJS(action.payload.value))
      );
    }
    case Actions.INSERT_BEFORE: {
      const indexes = findKeys(state, action.payload.name);
      if (!indexes) return state;
      const last = indexes.pop();
      const substate = state.getIn(indexes);
      if (!substate || !List.isList(substate)) return state;
      return state.setIn(
        indexes,
        substate.insert(last, fromJS(action.payload.value))
      );
    }
    case Actions.REMOVE: {
      const indexes = findKeys(state, action.payload.name);
      if (!indexes) return state;
      return state.removeIn(indexes);
    }
    case Actions.REPLACE: {
      const indexes = findKeys(state, action.payload.name);
      if (!indexes) return state;
      return state.setIn(indexes, fromJS(action.payload.value));
    }
    case Actions.REPLACE_ALL: {
      return fromJS(action.payload);
    }
    case Actions.RESET: {
      return fromJS(defaultMenu);
    }
    case Actions.APPEND: {
      return state.push(fromJS(action.payload));
    }
    case Actions.PREPEND: {
      return state.shift(fromJS(action.payload));
    }
    default:
      return state;
  }
}

function drawerVisibilityReducer(
  state: Map<string, boolean> = Map(),
  action: SidebarAction
) {
  switch (action.type) {
    case Actions.DRAWER_TOGGLE: {
      if (action.payload.mobile) {
        return state.set(
          "isMobileDrawerOpen",
          !state.get("isMobileDrawerOpen")
        );
      }
      return state.set("isDrawerOpen", !state.get("isDrawerOpen"));
    }
    case Actions.DRAWER_OPEN: {
      if (action.payload.mobile) {
        return state.set("isMobileDrawerOpen", true);
      }
      return state.set("isDrawerOpen", true);
    }
    case Actions.DRAWER_CLOSE: {
      if (action.payload.mobile) {
        return state.set("isMobileDrawerOpen", false);
      }
      return state.set("isDrawerOpen", false);
    }
    default:
      return state;
  }
}

export default function reducer(
  state: Map<string, any> = fromJS(defaultStore),
  action: SidebarAction
) {
  return state
    .set("menuItems", itemsReducer(state.get("menuItems"), action))
    .set(
      "drawerVisibilityState",
      drawerVisibilityReducer(state.get("drawerVisibilityState"), action)
    );
}

// Action creators
// In all action creators, we understand "name" as "name" in MenuItem.
// Remember that "name" should be unique, otherwise algorithm will find only the first uccurence.

/**
 * Inserts menu item after an item with name: name.
 * Item with name is found in hierarchy, if there is no such item with this name, does nothing.
 *
 * @param {string} name Name of item we want to insert out item before
 * @param {MenuItem} value Value of MenuItem we want to insert
 */
export function insertAfter(name: string, value: MenuItem): SidebarAction {
  return action(Actions.INSERT_AFTER, { name, value });
}

/**
 * Inserts menu item before an item with name: name.
 * Item with name is found in hierarchy, if there is no such item with this name, does nothing.
 * You should use this function if you want to insert some item to a very beginning of an array.
 *
 * @param {string} name Name of item we want to insert out item before
 * @param {MenuItem} value Value of MenuItem we want to insert
 */
export function insertBefore(name: string, value: MenuItem): SidebarAction {
  return action(Actions.INSERT_BEFORE, { name, value });
}

/**
 * Removes item with name. If such item does not exist, does nothing.
 *
 * @param {string} name Name of item to remove
 */
export function remove(name: string): SidebarAction {
  return action(Actions.REMOVE, { name, value: null });
}

/**
 * Replaces item with name with new value. If the old item has some children, they are not merged, but also replaced.
 * If such item does not exist, does nothing.
 *
 * @param {string} name Name of item to replace
 * @param {value} value New value
 */
export function replace(name: string, value: MenuItem): SidebarAction {
  return action(Actions.REPLACE, { name, value });
}

/**
 * Replaces entire menu.
 *
 * @param {Array<MenuItem>} items New menu
 */
export function replaceAll(items: Array<MenuItem>): SidebarAction {
  return action(Actions.REPLACE_ALL, items);
}

/**
 * Resets to default store.
 */
export function reset(): SidebarAction {
  return action(Actions.RESET);
}

/**
 * Appends item to the menu (at the end)
 * @param {MenuItem} item
 */
export function append(item: MenuItem): SidebarAction {
  return action(Actions.APPEND, item);
}

/**
 * Prepends item to the menu (at the start)
 * @param {MenuItem} item
 */
export function prepend(item: MenuItem): SidebarAction {
  return action(Actions.PREPEND, item);
}

/**
 * Toggles open/close menu
 * We usually do not put UI state into Redux however in this case we want more components to be able to toggle drawer
 */
export function toggleDrawer(mobile: boolean = false): SidebarAction {
  return action(Actions.DRAWER_TOGGLE, { mobile });
}

export function openDrawer(mobile: boolean = false): SidebarAction {
  return action(Actions.DRAWER_OPEN, { mobile });
}

export function closeDrawer(mobile: boolean = false): SidebarAction {
  return action(Actions.DRAWER_CLOSE, { mobile });
}
