// @flow
import api from "api";
import * as resources from "resources";

export const fetchXWikiPage = (wiki: string, space: string, page: string) => {
  return api.get(resources.DataGET.XWIKI_PAGE(wiki, space, page));
};

export const updateXWikiPage = (xWiki: Object) => {
  return api.put(resources.DataPUT.XWIKI_UPDATE(), xWiki);
};
