// @flow
import api from "api";
import * as resources from "resources";
import type { TeamRegistrationDto } from "./types";

export const fetchTreeNodeByContestAbbr = (abbr: string) => {
  return () => api.get(resources.TreeNodeGET.FOR_REGISTRATION_BY_ABBR(abbr));
};

export const fetchSiteDetailsDto = (siteId: number) => {
  return () => api.get(resources.DataGET.SITE_DETAILS_DTO(siteId));
};

export const fetchContestName = (siteId: number) => {
  return () => api.get(resources.DataGET.CONSTEST_IDNAME_BY_SITE_ID(siteId));
};

export const fetchSuggestByPersonId = (personId: number) => {
  return () =>
    api.get(resources.DataGET.INSTITUTION_SUGGEST_BY_PERSON_ID(personId));
};

export const fetchSuggestById = (iuaId: number) => {
  return () => api.get(resources.DataGET.INSTITUTION_SUGGEST_BY_IUA_ID(iuaId));
};

export const fetchPersonRegistrationDto = (id: number) => {
  return api.get(resources.DataGET.PERSON_REG_DTO(id));
};

export const registerTeams = (
  teamRegistrationDtos: Array<TeamRegistrationDto>
) => {
  return api.post(resources.DataPOST.TEAM_REGISTER_BULK, teamRegistrationDtos);
};

export const validateTeams = (siteId: number, teams: Array<string>) => {
  return api.post(resources.DataPOST.TEAM_VALIDATE_NAME_BULK(siteId), teams);
};

export const isSiteRegistrable = (siteId: number) => {
  return api.get(resources.DataGET.TEAM_IS_SITE_REGISTRABLE(siteId));
};
