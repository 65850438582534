// @flow
import api from "api";
import * as resources from "resources";
import type { Eligibility } from "./types";

export const fetchEligibility = (teamId: number) => {
  return api.get(resources.DataGET.ELIGIBILITY(teamId));
};

export const updateEligibility = (teamId: number, eligibility: Eligibility) => {
  return api.post(resources.DataPOST.ELIGIBILITY(teamId), eligibility);
};

export const revalidate = (teamId: number) => {
  return api.post(resources.DataPOST.ELIGIBILITY_REVALIDATE(teamId));
};
